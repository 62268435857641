import React, { Fragment, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import Layout from "../../../Layout"
import Container from "../../../Layout/Container"
import Button from "../../../Elements/Button"

import complete from "../../../../../static/images/icons/completed.gif"
import styles from "./utils/bookingComplete.module.scss"
import { FlexmedCashlessContext } from "../../FlexmedContext/FlexmedCashlessContext"

const OrderComplete = (props) => {
  const { pageContext, location } = props
  const { module, content, nextPath } = pageContext

  const { flexmedCashlessDispatch } = useContext(FlexmedCashlessContext)

  useEffect(() => {
    const restartDetails = async () => {
      await flexmedCashlessDispatch({ type: "RESET_DETAILS" })
      await flexmedCashlessDispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
    }
    restartDetails()
  }, [flexmedCashlessDispatch])

  return (
    <Layout {...pageContext?.module} pageContext={pageContext}>
      <Container isCentered>
        <div className={classNames("columns", styles["column"])}>
          {content.icon && (
            <div className="column is-4-desktop is-12-mobile">
              <figure className={classNames("image mx-3")}>
                <img
                  src={complete}
                  alt={`${module.name} Complete`}
                  className={styles["image"]}
                  width={128}
                />
              </figure>
            </div>
          )}
          <div className="column">
            <Fragment>
              <p className="mb-2">
                Thank you for submitting your FlexMed Cashless order.
              </p>
              <p className="mb-2">
                Please check{" "}
                {location?.state?.email ? (
                  <span className="has-text-weight-bold has-text-primary">
                    {location?.state?.email}
                  </span>
                ) : (
                  "your email"
                )}{" "}
                for your reference number. For faster processing, please provide
                your reference number if you have any order-related inquiries.
              </p>
              <p className="mb-2">
                You will receive an email confirming the eligibility of your
                request within the next working day. MedGrocer will update you
                when your medicines are ready for pick-up.
              </p>
            </Fragment>
          </div>
        </div>
        <center>
          <Button
            className={classNames(styles["complete__button"])}
            color="orange"
            size="medium"
            onClick={() => navigate(nextPath)}
          >
            Return to Homepage
          </Button>
        </center>
      </Container>
    </Layout>
  )
}

export default OrderComplete
